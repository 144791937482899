export default function useString() {
  function arrayToString(string: string[] | undefined): string {
    if (!string) return ''
    return string.join(', ')
  }

  function title(string: string): string {
    try {
      return string
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(' ')
    } catch (error) {
      return string
    }
  }

  function headline(string: string): string {
    return string
      .split(/[-_]/)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
  }

  function kebab(string: string): string {
    return string
      .split(/(?=[A-Z])|_|-|\.|\s/)
      .map((word) => word.toLowerCase())
      .join('-')
  }

  function removeDuplicates(string: string, word: string): string {
    const regex = new RegExp(`\\b(${word})\\b\\s+\\1\\b`, 'gi')
    return string.replace(regex, '$1')
  }

  function slug(string: string): string {
    return string
      .replace(/['’(),]/g, '') // Remove apostrophes
      .toLowerCase() // Convert to lowercase
      .split(/[^a-zA-Z0-9]+/) // Split by non-alphanumeric characters
      .join('-') // Join with hyphens
  }

  function stringToArray(string: string): string[] {
    return string.split(',').map((item) => item.trim())
  }

  function upper(string: string): string {
    return string.toUpperCase()
  }

  return { arrayToString, title, headline, kebab, removeDuplicates, slug, stringToArray, upper }
}
